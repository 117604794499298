import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
// componenets
import Gallery from '@/components/Gallery/Gallery.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let LodgeContent = class LodgeContent extends Vue {
};
__decorate([
    Prop()
], LodgeContent.prototype, "lodge", void 0);
LodgeContent = __decorate([
    Component({
        components: {
            Gallery,
            MarkdownFormatter,
        },
    })
], LodgeContent);
export default LodgeContent;
