import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
// components
import Btn from "@/components/Button/Btn.vue";
import Sidebar from "@/components/Elements/Sidebar.vue";
import Expander from "@/components/Expander/Expander.vue";
import SocialShare from "@/components/SocialShare/SocialShare.vue";
import ProfileCard from "@/components/ProfileCard/ProfileCard.vue";
import CoverImage from "@/components/Elements/CoverImage.vue";
import ExpanderMap from "@/components/Map/ExpanderMap/ExpanderMap.vue";
import OfferCard from "@/components/OfferCard/OfferCard.vue";
import SocialLinks from "@/components/SocialLinks/SocialLinks.vue";
import IndicativeRate from "@/views/Lodges/Components/IndicativeRate/IndicativeRate.vue";
import LodgeContent from "@/views/Lodges/Sections/LodgeContent.vue";
import OverlayBasic from "@/components/OverlayBasic/OverlayBasic.vue";
import TextInput from "@/components/FormInputs/TextInput/TextInput.vue";
import MemberLookup from "@/views/Members/Components/MemberLookup.vue";
import MarkdownFormatter from "@/components/MarkdownFormatter/MarkdownFormatter.vue";
import SelectInput from "@/components/FormInputs/SelectInput/SelectInput.vue";
import WishlistModal from "@/views/Wishlists/Components/WishlistModal.vue";
let LodgePage = class LodgePage extends Vue {
    constructor() {
        super(...arguments);
        this.indicativeRates = [];
        this.additionalRates = [];
        this.coverImage = "";
        this.coverImageCopyright = "";
        this.showEnquiryOverlay = false;
        this.showSignInOverlay = false;
        this.enquiryName = "";
        this.enquiryEmail = "";
        this.enquiryComment = "";
        this.isFavourited = false;
        this.errors = {
            name: [],
            email: [],
            comment: [],
            recaptchaToken: [],
        };
        // Wishlist
        this.showWishlistModal = false;
        // Send link to member
        this.showMemberSelect = false;
        this.shareInfo = {
            url: `${process.env.VUE_APP_URL}lodge/${this.$route.params.slug}`,
            title: `Africa Geographic - lodges`,
            description: "Get discounts at the top lodges in africa",
            quote: "Get discounts at the top lodges in africa",
            hashtags: `lodges,africa,africageographic,club`,
            twitterUser: "africageo",
        };
        this.showCurrencySelector = false;
        this.currencyOptions = [];
        this.currency = null;
        this.currencies = [];
    }
    // Check if lodge is already in user wishlists
    get isLodgeInWishlists() {
        return this.lodge.wishlists && this.lodge.wishlists.length > 0;
    }
    async currencyUpdated(newVal) {
        if (this.currency != null) {
            let selected = this.currencies.filter((c) => {
                return c.code === this.currency;
            });
            await this.$store.dispatch("app/setCurrency", selected[0]);
            // this.$store.dispatch('notification/showSuccess', 'Your preferred currency has been updated to ' + selected[0].name + '.')
        }
        else {
            await this.$store.dispatch("app/setCurrency", null);
            // this.$store.dispatch('notification/showSuccess', 'Your preferred currency has been reset.')
        }
    }
    async mounted() {
        await this.getLodge();
        if (this.$store.getters["auth/loggedIn"]) {
            await this.checkEngagements();
        }
        this.getCoverImage();
        this.getCoverImageCopyright();
        await this.$store.dispatch("engagements/setEngagementType", {
            engagementType: "",
            engagementSlug: "",
        });
        // Setup the currency selector
        await this.$store.dispatch("app/getCurrencies");
        this.currencies = this.$store.getters["app/currencies"];
        this.currencyOptions = this.currencies?.map((currency) => {
            return {
                name: `${currency.code} - ${currency.name}`,
                value: currency.code,
                selected: false,
            };
        });
        if (this.$store.getters["auth/loggedIn"]) {
            await this.$store.dispatch("wishlists/getWishlists");
            this.enquiryName = this.$store.getters["user/getProfile"].known_as;
            this.enquiryEmail = this.$store.getters["user/getProfile"].email;
        }
        if (this.$store.getters["app/selectedCurrency"] !== null) {
            this.currency = this.$store.getters["app/selectedCurrency"].code;
        }
    }
    async resetCurrency() {
        this.currency = null;
        this.showCurrencySelector = false;
        await this.$store.dispatch("app/setCurrency", null);
        this.$store.dispatch("notification/showSuccess", "Your preferred currency has been reset.");
    }
    async getLodge() {
        await this.$store.dispatch("lodges/getLodgeBySlug", this.$route.params.slug);
        // indicative Rates
        this.indicativeRates =
            this.lodge && this.lodge.rates
                ? this.lodge.rates.filter((rate) => rate.indication_rate === true)
                : [];
        // all other Rates
        this.additionalRates =
            this.lodge && this.lodge.rates
                ? this.lodge.rates.filter((rate) => rate.indication_rate === false)
                : [];
    }
    getCoverImage() {
        if (this.lodge && this.lodge.hero_image && this.lodge.hero_image.public_url) {
            // if the lodge has a hero image set
            this.coverImage = this.lodge.hero_image.public_url;
        }
        else if (this.lodge && this.lodge.hero_image_id && this.lodge.images) {
            // if lodge has hero_image_id and images
            this.coverImage = this.lodge.images.filter((image) => image.id === this.lodge.hero_image_id)[0].public_url;
        }
        else if (this.lodge && this.lodge.images && this.lodge.images.length > 0) {
            this.coverImage = this.lodge.images[0].public_url;
        }
    }
    getCoverImageCopyright() {
        if (this.lodge &&
            this.lodge.hero_image &&
            this.lodge.hero_image.public_url &&
            this.lodge.hero_image_copyright) {
            this.coverImageCopyright = this.lodge.hero_image_copyright;
        }
        else {
            return (this.coverImageCopyright = "");
        }
    }
    handleMoreInfoClick() {
        window.open(this.lodge.enquiry_external_url, "_blank");
    }
    async requestContact() {
        try {
            // Show the modal overlay
            this.showEnquiryOverlay = true;
        }
        catch (e) {
            console.log("Lodge.vue:218 -  e", e);
        }
    }
    async submitRequestContact() {
        this.errors = {
            name: [],
            email: [],
            comment: [],
            recaptchaToken: [],
        };
        try {
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('lodgeEnquiry');
            // Show the modal overlay
            await this.$store.dispatch("lodges/requestLodgeInformation", {
                name: this.enquiryName,
                email: this.enquiryEmail,
                comment: this.enquiryComment,
                slug: this.lodge.slug,
                recaptchaToken: token
            });
            this.showEnquiryOverlay = false;
        }
        catch (e) {
            console.log("Lodge.vue:218 -  e", e);
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
    async favourite() {
        await this.$store.dispatch("lodges/favourite", this.lodge.slug);
        await this.checkEngagements();
    }
    // Engagements
    async checkEngagements() {
        if (this.lodge &&
            this.lodge.favorite_lodges &&
            this.lodge.favorite_lodges.length > 0) {
            this.isFavourited = !!this.lodge.favorite_lodges.filter((user) => user.id === this.profile.id)[0];
        }
        else {
            this.isFavourited = false;
        }
    }
    numberFormat(price) {
        return new Intl.NumberFormat("fr-FR").format(price);
    }
    // Wishlist Modal
    async openWishlistModal() {
        if (!this.$store.getters['auth/loggedIn']) {
            // this.showSignInOverlay = true;
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.showWishlistModal = true;
    }
    async toggleMemberSelect() {
        if (!this.$store.getters['auth/loggedIn']) {
            // this.showSignInOverlay = true;
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.showMemberSelect = true;
    }
    // Loading from wishlist modal
    async setLoadingEvent(payload) {
        if (this.$store.getters["auth/loggedIn"]) {
            await this.$store.getters["wishlistst/getWishlists"];
        }
        await this.getLodge();
        this.showWishlistModal = false;
    }
};
__decorate([
    Getter("activeLodge", { namespace: "lodges" })
], LodgePage.prototype, "lodge", void 0);
__decorate([
    Getter("getProfile", { namespace: "user" })
], LodgePage.prototype, "profile", void 0);
__decorate([
    Getter("getWishlists", { namespace: "wishlists" })
], LodgePage.prototype, "wishlists", void 0);
__decorate([
    Watch("currency")
], LodgePage.prototype, "currencyUpdated", null);
LodgePage = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            Expander,
            SocialShare,
            ProfileCard,
            CoverImage,
            ExpanderMap,
            OfferCard,
            SocialLinks,
            IndicativeRate,
            LodgeContent,
            OverlayBasic,
            TextInput,
            MemberLookup,
            MarkdownFormatter,
            SelectInput,
            WishlistModal,
        },
        metaInfo() {
            return {
                title: "Africa Geographic | lodge",
                meta: [
                    {
                        name: "description",
                        content: "Africa Geographic lodge",
                    },
                ],
                link: [{ rel: "canonical", href: `${process.env.VUE_APP_URL}/lodge` }],
            };
        },
    })
], LodgePage);
export default LodgePage;
