import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
let WishlistModal = class WishlistModal extends Vue {
    constructor() {
        super(...arguments);
        this.checkedWishlists = [];
        this.formOpened = false;
        this.disabled = false;
        this.loading = false;
        // paginationLoaded: boolean = false
        this.error = '';
        this.form = {
            name: '',
        };
        this.wishlistableForm = {
            wishlistable_type: this.itemType,
            wishlistable_id: this.item.id,
            action: 'add-and-remove',
            wishlist_ids: [],
        };
        this.errors = {
            name: [],
            wishlistable_type: [],
            wishlistable_ids: [],
            wishlist_ids: [],
        };
    }
    async mounted() {
        await this.resetWishlistsValues();
        await this.$store.dispatch('wishlists/getModalWishlists');
        await this.getCheckedWishlists();
    }
    async beforeDestroy() {
        await this.resetWishlistsValues();
    }
    async resetWishlistsValues() {
        await this.$store.dispatch('wishlists/setSearch', '');
        await this.$store.dispatch('wishlists/setFilter', '');
        await this.$store.dispatch('wishlists/setPage', 1);
    }
    async getCheckedWishlists() {
        this.wishlists.forEach((wishlist) => {
            if (this.itemType === 'lodge') {
                wishlist.lodges?.forEach((lodge) => {
                    if (lodge.id === this.item.id) {
                        this.wishlistableForm.wishlist_ids.push(wishlist.id);
                    }
                });
            }
            else if (this.itemType === 'package') {
                wishlist.packages?.forEach((singlePackage) => {
                    if (singlePackage.id === this.item.id) {
                        this.wishlistableForm.wishlist_ids.push(wishlist.id);
                    }
                });
            }
        });
    }
    async closeWishlistModal() {
        this.$emit('loading-event', { loading: this.loading });
    }
    async clickWiew(wishlist) {
        await this.$store.dispatch('wishlists/setActiveWishlist', wishlist);
        this.$router.push({ path: `/wishlist/${wishlist.slug}` });
    }
    async addOrRemoveItem() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('wishlistable_type', this.wishlistableForm.wishlistable_type);
        formData.append('wishlistable_id', this.wishlistableForm.wishlistable_id);
        // append action
        formData.append('action', this.wishlistableForm.action);
        let wishlistCount = this.wishlistableForm.wishlist_ids ? this.wishlistableForm.wishlist_ids.length : 0;
        // make string array out of checked wishlists for formData
        for (var i = 0; i < wishlistCount; i++) {
            let wishlist = this.wishlistableForm.wishlist_ids[i];
            // append form data
            formData.append('wishlist_ids[]', wishlist);
        }
        try {
            this.loading = true;
            this.disabled = true;
            const resp = await this.$store.dispatch('wishlists/addOrRemoveItem', formData);
            await this.$store.dispatch('wishlists/getWishlists');
            await this.$store.dispatch('wishlists/getModalWishlists');
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: this.loading });
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.error = e.message;
            this.$emit('loading-event', { loading: this.loading });
        }
    }
    async submit() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('name', this.form.name);
        formData.append('wishlistable_type', this.itemType);
        formData.append('wishlistable_id', this.item.id);
        try {
            this.loading = true;
            this.disabled = true;
            const resp = await this.$store.dispatch('wishlists/create', formData);
            await this.$store.dispatch('wishlists/getWishlists');
            await this.$store.dispatch('wishlists/getModalWishlists');
            await this.getCheckedWishlists();
            this.form.name = '';
            this.disabled = false;
            this.formOpened = false;
            this.loading = false;
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.error = e.message;
        }
    }
    async openForm() {
        this.formOpened = true;
    }
    async cancel() {
        this.form.name = '';
        this.formOpened = false;
    }
};
__decorate([
    Getter('getModalWishlists', { namespace: 'wishlists' })
], WishlistModal.prototype, "wishlists", void 0);
__decorate([
    Prop()
], WishlistModal.prototype, "item", void 0);
__decorate([
    Prop({ default: 'lodge' })
], WishlistModal.prototype, "itemType", void 0);
WishlistModal = __decorate([
    Component({
        components: {
            Btn,
            TextInput,
        },
    })
], WishlistModal);
export default WishlistModal;
