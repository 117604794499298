import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LodgeService from '@/services/lodges';
import ProfileService from '@/services/user';
// Components
import Btn from '@/components/Button/Btn.vue';
import LodgeCard from '@/views/Lodges/Components/LodgeCard/LodgeCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
let Lodges = class Lodges extends Vue {
    constructor() {
        super(...arguments);
        this.allLodges = [];
        this.lodges = [];
        this.searchValue = '';
        this.searchError = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.showFilters = true;
        this.onlyFavourites = false;
        this.showMap = false;
        // GmapMap props
        this.zoom = 2.5;
        this.center = {
            lat: 2.3185,
            lng: 19.5687,
        };
        this.mapOptions = {
            styles: [
                {
                    featureType: 'poi',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
            ],
        };
        // GmapInfoWindow props
        this.infoWinOpen = false;
        this.infoWindowPos = null;
        this.infoOptions = {
            pixelOffset: {
                width: 0,
                height: -35,
            },
            maxWidth: 800,
        };
        this.currentLodgeIndex = 0;
        this.currentLodge = null;
        // GmapMarker icons
        this.mapMarker = `/img/grey-map-marker.svg`;
        this.activeMapMarker = `/img/red-map-marker.svg`;
        this.coverImageDesc = 'Africa’s most authentic camps & lodges – at the BEST prices. Plus, our team of seasoned safari experts on call.';
        this.onlyNonMalaria = false;
        // Destination
        this.destinations = [];
        this.destination = null;
        this.destinationOptions = [];
        // Currency
        this.currencyOptions = [];
        this.currency = null;
        this.currencies = [];
        this.searchTimeout = null;
        this.cardIcon = ['fal', 'ellipsis-v'];
    }
    async searchUpdated(newVal) {
        // We do this timeout cllear timeout dance
        // so that the search is only triggered 300ms after the user has stopped typing.
        // We trigger loading immediately though to provide feedback
        clearTimeout(this.searchTimeout);
        this.loading = true;
        let self = this;
        this.searchTimeout = setTimeout(async function () {
            // Set the search term & rest page
            await self.$store.dispatch('lodges/setSearch', newVal);
            if (self.$route.query.search !== newVal) {
                await self.$store.dispatch('lodges/setPage', 1);
            }
            else {
                await self.$store.dispatch('lodges/setPage', self.$route.query.page);
            }
            // await this.$store.dispatch('lodges/getAllSearchResults')
            await self.showMap ? self.getAllLodges() : self.getLodges();
            self.loading = false;
        }, 700);
    }
    async currencyUpdated(newVal) {
        if (this.currency != null) {
            let selected = this.currencies.filter((c) => {
                return c.code === this.currency;
            });
            await this.$store.dispatch('app/setCurrency', selected[0]);
        }
        else {
            await this.$store.dispatch('app/setCurrency', null);
        }
    }
    async destinationUpdated(newVal) {
        this.loading = true;
        // Set the destination & rest page
        await this.$store.dispatch('destinations/setDestination', newVal);
        if (this.$route.query.destination !== newVal) {
            await this.$store.dispatch('lodges/setPage', 1);
        }
        else {
            await this.$store.dispatch('lodges/setPage', this.$route.query.page);
        }
        await this.getLodges();
        this.loading = false;
    }
    created() {
        if (history.state.search && history.state.destination && history.state.page) {
            if (this.$route.fullPath === `/lodges?search=${history.state.search}&destination=${history.state.destination}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/lodges?search=${history.state.search}&destination=${history.state.destination}&page=${history.state.page}` });
            }
        }
        else {
            if (history.state.search && history.state.page) {
                if (this.$route.fullPath === `/lodges?search=${history.state.search}&page=${history.state.page}`) {
                    return;
                }
                else {
                    this.$router.push({ path: `/lodges?search=${history.state.search}&page=${history.state.page}` });
                }
            }
            if (history.state.destination && history.state.page) {
                if (this.$route.fullPath === `/lodges?destination=${history.state.destination}&page=${history.state.page}`) {
                    return;
                }
                else {
                    this.$router.push({ path: `/lodges?destination=${history.state.destination}&page=${history.state.page}` });
                }
            }
        }
    }
    async mounted() {
        await this.resetLodgeValues();
        if (this.$route.query.search) {
            await this.$store.dispatch('lodges/setSearch', this.$route.query.search);
            this.searchValue = this.$store.getters['lodges/getSearchTerm'];
        }
        if (this.$route.query.non_malaria) {
            await this.$store.dispatch('lodges/setOnlyNonMalaria', this.$route.query.non_malaria);
            this.onlyNonMalaria = this.$store.getters['lodges/getOnlyNonMalaria'];
        }
        if (this.$route.query.favorite) {
            await this.$store.dispatch('lodges/setOnlyFavourites', this.$route.query.favorite);
            this.onlyFavourites = this.$store.getters['lodges/getOnlyFavourites'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('lodges/setPage', this.$route.query.page);
        }
        if (this.$route.query.destination) {
            await this.$store.dispatch('destinations/setDestination', this.$route.query.destination);
            this.destination = this.$store.getters['destinations/selectedDestination'];
        }
        await this.showMap ? this.getAllLodges() : this.getLodges();
        this.paginationLoaded = true;
        this.loading = false;
        // setup the destination selector
        this.destinationSelectorSetup();
        // Setup the currency selector
        await this.$store.dispatch('app/getCurrencies');
        this.currencies = this.$store.getters['app/currencies'];
        this.currencyOptions = this.currencies?.map((currency) => {
            return {
                name: `${currency.code} - ${currency.name}`,
                value: currency.code,
                selected: false,
            };
        });
        if (this.$store.getters['app/selectedCurrency'] !== null) {
            this.currency = this.$store.getters['app/selectedCurrency'].code;
        }
        await this.getLodgesCache();
        if (this.$store.getters['auth/loggedIn']) {
            await ProfileService.updateLastActivity("lodges");
        }
    }
    async getLodgesCache() {
        try {
            const response = await LodgeService.getCache();
            this.allLodges = response.data.lodges;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async getLodges() {
        try {
            const response = await LodgeService.get();
            this.lodges = response.data.lodges;
            this.$store.dispatch('pagination/setPagination', response.data.pagination);
            this.$store.dispatch('user/resetSingleResourceCount', 'lodges');
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async getAllLodges() {
        try {
            const response = await LodgeService.getAllSearch();
            this.lodges = response.data.lodges;
            this.$store.dispatch('pagination/setPagination', response.data.pagination);
            this.$store.dispatch('user/resetSingleResourceCount', 'lodges');
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async destinationSelectorSetup() {
        // Setup the destination selector
        await this.$store.dispatch('destinations/getDestinations', 'lodge');
        this.destinations = this.$store.getters['destinations/destinations'];
        // Initial data
        let TemDestinations = [
            {
                name: 'All Places',
                value: '',
                selected: false,
            }
        ];
        // Loop through destinations
        this.destinations?.forEach((destination) => {
            TemDestinations.push({
                name: `${destination.name}`,
                value: destination.id,
                selected: false,
            });
            // If destination has children
            if (destination.children) {
                // Loop through children
                destination.children.forEach((child) => {
                    TemDestinations.push({
                        name: `-- ${child.name}`,
                        value: child.id,
                        selected: false,
                    });
                });
            }
        });
        // set the destination option selector
        this.destinationOptions = TemDestinations;
        if (this.$store.getters['destinations/selectedDestination'] !== null) {
            this.destination = this.$store.getters['destinations/selectedDestination'];
        }
    }
    async beforeDestroy() {
        await this.resetLodgeValues();
        // Reset destination data
        await this.$store.commit('destinations/SET_EMPTY');
    }
    async resetCurrency() {
        this.currency = null;
        await this.$store.dispatch('app/setCurrency', null);
    }
    async filterFavourites() {
        this.onlyFavourites = !this.onlyFavourites;
        await this.$store.dispatch('lodges/setOnlyFavourites', this.onlyFavourites);
        await this.showMap ? this.getAllLodges() : this.getLodges();
    }
    async filterNonMalaria() {
        this.onlyNonMalaria = !this.onlyNonMalaria;
        await this.$store.dispatch('lodges/setOnlyNonMalaria', this.onlyNonMalaria);
        await this.showMap ? this.getAllLodges() : this.getLodges();
    }
    async toggleFilters() {
        this.showFilters = !this.showFilters;
        if (!this.showFilters) {
            this.destination = null;
            await this.$store.dispatch('destinations/setDestination', null);
            await this.$store.dispatch('lodges/setFilter', '');
            await this.$store.dispatch('lodges/setOnlyFavourites', false);
            await this.$store.dispatch('lodges/setOnlyNonMalaria', false);
            await this.showMap ? this.getAllLodges() : this.getLodges();
            this.onlyFavourites = false;
        }
    }
    async toggleMap(showMap) {
        this.showMap = showMap;
        if (!this.showMap)
            this.closeInfoWindow();
        await this.showMap ? this.getAllLodges() : this.getLodges();
    }
    get mapCenter() {
        var maxLat = 0;
        var maxLng = 0;
        this.allLodges.forEach((lodge) => {
            if (lodge.lat > maxLat) {
                maxLat = lodge.lat;
            }
            if (lodge.lng > maxLng) {
                maxLng = lodge.lng;
            }
        });
        var center = {
            lat: maxLat / 2,
            lng: maxLng / 2,
        };
        return center;
    }
    getMarker(lodgeID) {
        var marker = this.mapMarker;
        // if (this.searchValue != '' || this.onlyFavourites) {
        this.lodges.forEach((searchResult) => {
            if (searchResult.id == lodgeID) {
                marker = this.activeMapMarker;
            }
        });
        // }
        return marker;
    }
    async toggleInfoWindow(lodge, index) {
        this.infoWindowPos = {
            lat: parseFloat(lodge.lat),
            lng: parseFloat(lodge.lng),
        };
        this.currentLodge = lodge;
        // Check if its the same lodge that was selected if yes toggle
        if (this.currentLodgeIndex == index) {
            this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
            this.infoWinOpen = true;
            this.currentLodgeIndex = index;
        }
    }
    closeInfoWindow() {
        this.infoWinOpen = false;
    }
    updateZoom(event) {
        this.zoom = event;
    }
    displayLabel(lodge) {
        var label = {
            text: lodge.name,
            color: '#2a2a2a',
            fontWeight: 'Bold',
            fontSize: '10px',
        };
        // Display the label only when
        // the zoom is greater than 7
        return this.zoom > 6 ? label : null;
    }
    async resetLodgeValues() {
        await this.$store.dispatch('lodges/setSearch', '');
        await this.$store.commit('lodges/SET_ONLY_FAVOURITES', false);
        await this.$store.commit('lodges/SET_ONLY_NON_MALARIA', false);
        await this.$store.dispatch('lodges/setFilter', '');
        await this.$store.dispatch('lodges/setPage', 1);
    }
    async paginate(page) {
        await this.$store.dispatch('lodges/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.searchValue != '') {
            query['search'] = this.searchValue;
        }
        if (this.onlyNonMalaria) {
            query['non_malaria'] = this.onlyNonMalaria;
        }
        if (this.onlyFavourites) {
            query['favorite'] = this.onlyFavourites;
        }
        if (this.destination != null) {
            query['destination'] = this.destination;
        }
        this.$router.push({
            name: 'lodges',
            query: query,
        });
    }
};
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], Lodges.prototype, "pagination", void 0);
__decorate([
    Watch('searchValue')
], Lodges.prototype, "searchUpdated", null);
__decorate([
    Watch('currency')
], Lodges.prototype, "currencyUpdated", null);
__decorate([
    Watch('destination')
], Lodges.prototype, "destinationUpdated", null);
Lodges = __decorate([
    Component({
        components: {
            Btn,
            LodgeCard,
            TextInput,
            SearchInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | lodges',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic lodges',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/lodges` }],
            };
        },
    })
], Lodges);
export default Lodges;
